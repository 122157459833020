@import './../../Variables.scss';

.profile-form {
  .form-control:disabled {
    background-color: map-get($theme-colors, 'white');
  }
}

.credit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .credit-star {
    width: 64px;
    height: 64px;
  }
}

.delete-icon:hover {
  color: black;
}

#delete-modal-text {
  text-align: center !important;
  color: black;
}

#fileCommercialContract {
  height: 200px !important;
}

.custom-file {
  height: auto;
}

.custom-file-label[for='fileCommercialContract'] {
  border: 1.5px grey dashed !important;
  height: 200px !important;
  text-align: center !important;
  line-height: 185px;
}

.custom-file-label[for='fileCommercialContract']::after {
  content: '' !important;
  padding: 0 !important;
}

.custom-switch {
  z-index: 0;
}

.delete-icon:hover {
  color: inherit !important;
}

.react-select-disabled {
  background-color: #f0f0f0 !important;
  cursor: not-allowed !important;
  opacity: 0.5 !important;
  pointer-events: none !important;
}
