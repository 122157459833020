@import './../../Variables.scss';

.badge {
  &.to-do {
    border-radius: 50%;
    background-color: map-get($theme-colors, 'red');
    font-size: 14px;
  }
  &.to-do-green {
    background-color: map-get($theme-colors, 'success');
  }
}
