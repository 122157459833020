@import './../../Variables.scss';

.fa-check-circle {
  color: map-get($theme-colors, 'success');
}

.fa-info-circle {
  color: map-get($theme-colors, 'warning');
}

.fa-exclamation-circle {
  color: map-get($theme-colors, 'danger');
}

.info {
  background-color: map-get($theme-colors, 'warning');
}

.success {
  background-color: map-get($theme-colors, 'success');
}

.error {
  background-color: map-get($theme-colors, 'danger');
}
