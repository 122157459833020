// VARIABLES

$theme-colors: (
  'background': #f3f3f3,
  'black': #333333,
  'dark-black': #000000,
  'white': #ffffff,
  'primary': #115e96,
  'dark-primary': #17344d,
  'light-primary': #fff9e2,
  'grey': #e0e0e0,
  'dark-grey': #666666,
  'success': #4caf50,
  'warning': #ff8155,
  'red': #ff5252,
  'blue': #6ba8b2,
  'alert': #fb8c00,
  'neutral': #8a8c8d,
  'light-grey': #eaeff3,
  'text': #000501,
  'secondary': #607584,
  'accent': #a61916,
  'info': #2196f3,
  'table-thead': #f6f9fb,
);

$Roboto: 'Roboto', sans-serif;
$RobotoSlab: 'Roboto Slab', serif;

$menu-size: 200px;
