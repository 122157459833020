@import './../../Variables.scss';

#attachedFile {
  height: 200px !important;
}

.custom-file {
  height: auto;
}

.custom-file-label[for='attachedFile'] {
  border: 1.5px grey dashed !important;
  height: 200px !important;
  text-align: center !important;
  line-height: 185px;
}

.custom-file-label[for='attachedFile']::after {
  content: '' !important;
  padding: 0 !important;
}

.custom-switch {
  z-index: 0;
}

.delete-icon:hover {
  color: inherit !important;
}

.placeholder-option {
  color: #aaa;
}

select option:not(.placeholder-option) {
  color: #000;
}

.text-center {
  text-align: center !important;
}

.badge-draft {
  background-color: #808080 !important;
}

.badge-compilation {
  background-color: #007bff !important;
}

.badge-revision {
  background-color: #b49901 !important;
}

.badge-sign-req {
  background-color: #ffa500 !important;
}

.badge-signed {
  background-color: #28a745 !important;
}

.error-message {
  color: map-get($theme-colors, 'red') !important;
  font-size: 10px;
}

.agreement-label {
  .form-group {
    label {
      font-size: 12px;
    }
  }
}
