@import './../../Variables.scss';

.burger-menu {
  // display: none;
  .bm-burger-button {
    width: 25px;
    position: fixed;
    top: 19px;
    left: 22px;
    button {
      width: 25px !important;
      height: 25px !important;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
  // @media only screen and (max-width: 991px) {
  //   display: block;
  // }
}

.bm-item-list {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
