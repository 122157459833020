@import './../../Variables.scss';

.data-table {
  text-align: center;

  thead {
    background-color: map-get($theme-colors, 'grey');

    tr {
      th {
        border-right: 2px solid map-get($theme-colors, 'background');
        color: map-get($theme-colors, 'black');
        font-size: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        text-transform: uppercase;
        font-weight: 700;
        vertical-align: middle;
        user-select: none;
        &:last-child {
          border: 0;
        }
      }
    }
  }

  tbody {
    background-color: map-get($theme-colors, 'white');
    tr {
      &:first-child {
        td {
          border-top: 0;
        }
      }
      td {
        color: map-get($theme-colors, 'dark-grey');
        border-right: 2px solid map-get($theme-colors, 'background');
        border-top: 2px solid map-get($theme-colors, 'background');
        font-size: 11px;
        padding-top: 6px;
        padding-bottom: 6px;
        vertical-align: middle;
        &:last-child {
          border-right: 0;
        }
        .badge {
          background-color: map-get($theme-colors, 'blue');
          border-radius: 10px;
          color: map-get($theme-colors, 'white');
          font-size: 12px;
          font-weight: 400;
          padding: 3px 10px;
        }
        &.type {
          color: map-get($theme-colors, 'dark-black');
          font-weight: 700;
          font-style: italic;
        }
        &.title {
          font-weight: 700;
          color: map-get($theme-colors, 'black');
        }
        &.master-yes {
          .badge {
            background-color: map-get($theme-colors, 'success') !important;
          }
        }
        &.master-no {
          .badge {
            background-color: map-get($theme-colors, 'red') !important;
          }
        }
        // &:first-child {
        //   font-weight: 700;
        // }

        // &.close-date {
        //   color: map-get($theme-colors, "primary-contrast");
        //   font-weight: 700;
        // }
      }
      &:hover {
        background-color: rgba(map-get($theme-colors, 'background'), 0.5);
        cursor: pointer;
      }
      .flex-check {
        display: flex;
        align-items: center;
        justify-content: center;
        .custom-checkbox {
          label {
            &::before {
              border-radius: 0 !important;
            }
          }
        }
      }
    }
  }
}

.master-yes {
  .badge {
    background-color: map-get($theme-colors, 'success') !important;
  }
}

.master-no {
  .badge {
    background-color: map-get($theme-colors, 'red') !important;
  }
}

.regolarizzata {
  margin-bottom: 0;
  font-weight: 700;
  color: map-get($theme-colors, 'success');
  text-transform: uppercase;
  span {
    color: map-get($theme-colors, 'black');
  }
}

.non-regolarizzata {
  margin-bottom: 0;
  font-weight: 700;
  color: map-get($theme-colors, 'red');
  text-transform: uppercase;
}

.no-pointer {
  cursor: initial !important;
}

.table-responsive {
  overflow: auto;
  min-height: auto;
  max-height: 600px;
  thead tr:nth-child(1) th {
    background-color: map-get($theme-colors, 'grey');
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.table thead th {
  border-bottom: 0;
}

.table th {
  border-top: 0 !important;
}

.not-paid {
  .badge {
    background-color: #d35400 !important;
  }
}

.not-yet-active {
  .badge {
    background-color: #d3be00 !important;
  }
}
