@import './../../Variables.scss';

.search-bar {
  label.custom-control-label {
    color: red;
  }

  input {
    border: 0;
    font-weight: 0;
    border-radius: 5px;
    color: map-get($theme-colors, 'primary');
    background-color: map-get($theme-colors, 'white');

    &::placeholder {
      font-weight: normal;
    }

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: rgba(map-get($theme-colors, 'primary'), 0.6);

      &::placeholder {
        color: map-get($theme-colors, 'dark-primary');
        font-weight: 500;
      }
    }
  }
  button {
    margin-top: 0;
    font-size: initial;
    background-color: map-get($theme-colors, 'primary');
    transition: 0.2s;
    border-radius: 5px;
    color: map-get($theme-colors, 'light-primary');
    &:hover {
      outline: 0;
      box-shadow: none;
      background-color: map-get($theme-colors, 'dark-primary');
    }

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
      background-color: map-get($theme-colors, 'primary');
    }
  }
}
