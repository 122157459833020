@import './../../Variables.scss';

.search-add-cont {
  button {
    margin-bottom: 15px;
    float: right;
    @media only screen and (max-width: 1196px) {
      &.position-btn-filter {
        margin-top: 20px;
      }
    }
    @media only screen and (max-width: 991px) {
      float: inherit;
    }
  }
  @media only screen and (max-width: 767px) {
    .search-bar {
      input {
        font-size: initial;
      }
    }
  }
}
