.cke_notification_warning {
  display: none !important;
}

.cke_bottom {
  display: none !important;
}

.editor-container {
  display: flex;
  flex-direction: row;
  height: 45rem;
}

.editor {
  flex: 3;
  margin-right: 20px;
  height: 40rem;
}

.cke_contents {
  height: 42rem !important;
}

.comments-title {
  background-color: #f8f8f8;
}

.comments-bar {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  background: #f9f9f9;
  overflow-x: hidden !important;
}

.comments-bar h3 {
  margin-top: 0;
}

.comment {
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cke_button__comment {
  .cke_button_icon {
    display: none;
  }

  .cke_button_label {
    display: contents;
    font-weight: bold;
  }
}

.comment-text {
  overflow-wrap: break-word;
}
