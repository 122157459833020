@import './Variables.scss';

//GENERAL

body {
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: $Roboto;
  background-color: map-get($theme-colors, 'background');
}

.corpo {
  position: relative;
  height: 100vh;
  // width: calc(100% - 200px);
  width: 100%;
  padding: 83px 32px 34px;
  float: left;
  overflow-y: scroll;

  @media only screen and (max-width: 991px) {
    // width: 100%;
    // height: 100%;
    overflow-y: auto;
    padding: 121px 16px 40px;
  }
}

// NAVBAR MENU

nav {
  font-size: 0.8rem;
  background-color: map-get($theme-colors, 'white');
  overflow: hidden;
  height: 100%;
  text-align: left;
  border-right: 3px solid map-get($theme-colors, 'primary');

  .cont-logo-menu {
    padding: 30px 20px 20px;
    border-bottom: 1px solid #d1d1d1;
    text-align: center;

    img {
      width: 80%;
      margin: auto;
    }
  }

  .cont-link-menu {
    padding: 30px 20px;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      margin-bottom: 25px;
      font-family: $RobotoSlab;
      color: map-get($theme-colors, 'neutral');
      text-transform: uppercase;
      transition: 0.2s ease-in-out;

      svg {
        width: 25px;
        min-width: 25px;
        height: 100%;
        transition: 0.2s ease-in-out;
        fill: map-get($theme-colors, 'neutral');
        margin-right: 15px;
      }

      &:hover,
      &.active,
      &:active {
        color: map-get($theme-colors, 'primary');
        text-decoration: none;
        font-weight: 700;

        svg {
          &.hover-color {
            fill: map-get($theme-colors, 'primary');
          }
        }

        &:before {
          transform: translateX(0px);
        }
      }
    }
  }
}

.nav-desktop {
  position: relative;
  width: $menu-size;
  z-index: 100;

  nav {
    float: left;
    top: 0;
    left: 0;
    height: 100vh;
    width: $menu-size;
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }
}

//CENTRATURA ELEMENTI

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

// BACKGROUND

.u-bg-light {
  background-color: map-get($theme-colors, 'white');
}

// CONT SHADOW - CARD

.card {
  &.login-card {
    max-width: 600px;

    .card-header {
      background-color: map-get($theme-colors, 'white');
      border: none;
      text-align: center;
      padding-top: 30px;

      .card-title {
        font-size: 20px;
        color: map-get($theme-colors, 'darkborder');
        font-weight: 500;

        span {
          color: map-get($theme-colors, 'black');
          font-weight: 800;
        }
      }
    }

    .card-body {
      padding-right: 40px !important;
      padding-left: 40px !important;

      .btn {
        min-width: 200px;
      }
    }

    .card-footer {
      background-color: map-get($theme-colors, 'primary');
    }
  }
}

.card {
  background-color: map-get($theme-colors, 'white');
  border-radius: 0px;
  border: none;
  overflow: hidden;
  margin-bottom: 32px;

  .card-header {
    background-color: map-get($theme-colors, 'primary');
    border: 0;

    .card-title {
      margin-bottom: 0;
      color: map-get($theme-colors, 'white');
      font-weight: 700;
    }
  }

  .card-body {
    padding: 32px 16px;
  }

  .card-footer {
    background-color: map-get($theme-colors, 'light-primary');
    padding-top: 16px;
    padding-bottom: 16px;
    color: map-get($theme-colors, 'darkborder');

    .row {
      .col-6 {
        &:first-child {
          font-weight: 700;
        }

        &:last-child {
          a {
            color: map-get($theme-colors, 'primary');
          }
        }
      }
    }
  }
}

.cont-white-shadow {
  background-color: map-get($theme-colors, 'white');
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 15px;
  border-radius: 20px;
}

// FORM E LABEL

form {
  .form-group {
    margin-bottom: 24px;

    label {
      color: map-get($theme-colors, 'dark-grey');
      font-size: 12px;

      svg {
        margin-right: 5px;
      }

      &.link-psw {
        a {
          color: map-get($theme-colors, 'primary');
          font-family: $RobotoSlab;
          font-weight: 700;

          span {
            border-bottom: 2px solid map-get($theme-colors, 'primary');
            padding-bottom: 2px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .error-message {
      color: map-get($theme-colors, 'red') !important;
    }

    input,
    textarea,
    select {
      border: 2px solid map-get($theme-colors, 'grey');
      border-radius: 0;
      font-weight: 500;
      color: map-get($theme-colors, 'black');
      background-color: transparent;

      &:focus {
        box-shadow: none;
        border-color: map-get($theme-colors, 'dark-grey');
        background-color: transparent;
      }
    }

    .custom-file {
      label {
        margin-bottom: 0;
        border-radius: 0;
        border: 2px solid map-get($theme-colors, 'grey');
        font-weight: 500;
        height: 100%;
        font-size: 1rem;

        &:focus {
          box-shadow: none;
          outline: 0;
          border-color: map-get($theme-colors, 'dark-grey');
        }
      }

      input {
        &:focus {
          box-shadow: none !important;
          outline: 0;
          border-color: map-get($theme-colors, 'dark-grey');
        }
      }
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: 'Esplora';
      border-radius: 0;
      color: map-get($theme-colors, 'black');
      border: 0;
      background-color: map-get($theme-colors, 'grey');
      font-weight: 700;
    }

    .form-text {
      font-size: 10px;
      color: map-get($theme-colors, 'grey');
    }

    .custom-control-label {
      font-style: italic;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: map-get($theme-colors, 'primary-contrast');
      background-color: map-get($theme-colors, 'primary-contrast');
    }
  }

  @media only screen and(max-width: 1564px) {
    &.filter {
      .form-group {
        width: 20%;
        padding-right: 8px;

        label {
          width: 100%;
          justify-content: left;
        }

        select {
          width: 100%;
          justify-content: left;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }

        input {
          width: 100%;
          margin-right: 0px !important;
          margin-left: 0px !important;
        }
      }
    }
  }

  @media only screen and(max-width: 837px) {
    &.filter {
      .form-group {
        width: 33.3%;

        &:nth-child(4),
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and(max-width: 575px) {
    &.filter {
      .form-group {
        margin-bottom: 0;

        label {
          margin-bottom: 0;
        }

        // input{
        //   overflow: hidden;
        // }
      }
    }
  }
}

.select-wrapper {
  .form-group {
    height: 100%;

    select.custom-select {
      height: 100%;
    }
  }
}

// BUTTON

.btn {
  background-color: map-get($theme-colors, 'primary');
  color: map-get($theme-colors, 'white');
  border: 0;
  border-radius: 5px;
  padding: 7px 25px !important;
  margin-top: 16px;
  font-weight: 600;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 15px;
  font-size: 20px;

  &:hover {
    background-color: map-get($theme-colors, 'primary');
    color: map-get($theme-colors, 'white');
  }

  img {
    width: 25px;
    margin-right: 7px;
  }
}

.btn-plus {
  background-color: map-get($theme-colors, 'primary');
  font-size: 15px;
  margin-top: 0;

  img {
    width: 20px;
  }
}

.btn-collapse {
  margin-top: 0;
  margin-bottom: 0 !important;
  font-size: 16px;
  color: map-get($theme-colors, 'primary');
  background-color: white;

  &:focus {
    -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    outline: none;
  }
}

.position-btn-filter {
  position: absolute;
  top: 0;
  right: 16px;

  @media only screen and (max-width: 1196px) {
    position: relative;
    top: inherit;
    right: inherit;
    width: 100%;
  }

  @media only screen and (max-width: 575px) {
    margin-top: 0;
  }
}

.resp-btn {
  @media only screen and (max-width: 991px) {
    margin-top: 15px;
  }

  @media only screen and (max-width: 575px) {
    margin-top: 0;
  }
}

// TEXT

h4 {
  color: map-get($theme-colors, 'primary');
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 16px;
}

.uppercase {
  text-transform: uppercase;
}

.no-activities {
  color: map-get($theme-colors, 'primary');
  text-align: center;
  font-weight: 600;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.text-bold {
  font-weight: 800;
}

// MARGIN - PADDING

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-tb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-t-responsive {
  @media only screen and (max-width: 767px) {
    margin-top: 20px;
  }
}

// CONT-GREY

.cont-grey {
  background-color: map-get($theme-colors, 'background');
  margin-top: 20px;
  margin-bottom: 20px;

  .cont-grey-header {
    padding: 24px 16px;
    border-bottom: 1px solid map-get($theme-colors, 'border');

    .col-6 {
      &:first-child {
        h6 {
          color: map-get($theme-colors, 'primary');
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 0;
        }
      }

      &:last-child {
        h6 {
          color: map-get($theme-colors, 'primary-contrast');
          font-size: 18px;
          margin-bottom: 0;
        }
      }
    }
  }

  .cont-grey-body {
    padding: 16px;
    color: map-get($theme-colors, 'black');

    p {
      margin-bottom: 0;
    }
  }
}

// REACT-DATE PICKER

.react-datepicker__input-container {
  input {
    width: 100%;
    padding: 6px 12px;

    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

.react-datepicker-wrapper {
  input {
    padding: 6px 12px;

    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

.react-datepicker {
  border: 1px solid map-get($theme-colors, 'border');
  font-family: $Roboto;

  .react-datepicker__triangle {
    border-bottom-color: map-get($theme-colors, 'border');
  }

  .react-datepicker__header {
    border-bottom: 1px solid map-get($theme-colors, 'border');

    .react-datepicker__current-month {
      color: map-get($theme-colors, 'primary');
      font-family: $RobotoSlab;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .react-datepicker__header__dropdown,
    .react-datepicker__year-dropdown-container {
      .react-datepicker__month-read-view,
      .react-datepicker__year-read-view {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: map-get($theme-colors, 'black');
      }
    }
  }
}

.react-datepicker__day {
  color: map-get($theme-colors, 'dark-grey') !important;

  &:hover {
    border-radius: 50%;
  }
}

.react-datepicker__day--today {
  border-radius: 50%;
  background-color: map-get($theme-colors, 'light-primary');
  color: map-get($theme-colors, 'primary') !important;

  &:hover {
    background-color: map-get($theme-colors, 'primary');
    color: map-get($theme-colors, 'white') !important;
  }
}

.react-datepicker__day--selected {
  background-color: map-get($theme-colors, 'primary') !important;
  color: map-get($theme-colors, 'white') !important;
  border-radius: 50%;
}

.react-datepicker__month-container {
  .react-datepicker__header {
    padding: 12px 0;
  }

  .react-datepicker__month {
    padding: 20px;

    .react-datepicker__month-wrapper {
      font-size: 18px;

      .react-datepicker__month--selected {
        background-color: map-get($theme-colors, 'primary') !important;
        color: map-get($theme-colors, 'white') !important;
      }
    }
  }
}

// MODAL

.modal-dialog {
  max-width: 700px;
}

.modal-content {
  border: 0;
  background-color: map-get($theme-colors, 'white');
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0px;

  .modal-header {
    background-color: map-get($theme-colors, 'white');
    color: map-get($theme-colors, 'black');
    text-align: center;
    border-radius: 0;
    border-bottom: 0;

    .modal-title {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-family: $RobotoSlab;
      font-weight: 800;
      font-size: 16px;
    }

    .close {
      color: map-get($theme-colors, 'dark-grey');
    }
  }

  .modal-body {
    p {
      color: map-get($theme-colors, 'dark-grey');
      text-align: center;
    }

    .max-width-form {
      max-width: 70%;
      margin: auto;
    }
  }

  .modal-footer {
    border-top: 0;
    justify-content: center;

    .btn {
      margin-top: 0;
      font-size: 15px;
    }

    .btn-secondary {
      background-color: map-get($theme-colors, 'red');
    }
  }
}

.pay {
  background-color: map-get($theme-colors, 'success');
  border-radius: 0;
  margin-right: 10px;
  padding: 5px 12px;
}

.partial-paid {
  background-color: map-get($theme-colors, 'alert');
  color: map-get($theme-colors, 'black');
  border-radius: 0;
  margin-right: 10px;
  padding: 5px 12px;
}

.not-pay {
  background-color: map-get($theme-colors, 'red');
  border-radius: 0;
  margin-right: 10px;
  padding: 5px 12px;
}

.radio-modal-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .custom-radio {
    &:last-child {
      margin-left: 15px;
    }

    .custom-control-label {
      font-style: inherit;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.txt-table-left {
  text-align: left;
}

.txt-table-right {
  text-align: right;
}

// compensi variabili

.compensi-variabili-resp {
  .search-bar {
    margin-bottom: 16px;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.pagination-select {
  border-radius: 0 !important;
  border: 2px solid map-get($theme-colors, 'grey');
  border-bottom-color: map-get($theme-colors, 'primary');
}

.ml-20 {
  margin-left: 20px;
}

.not-allowed {
  cursor: not-allowed !important;
}

div.form-group {
  label {
    color: map-get($theme-colors, 'neutral') !important;
  }

  input,
  select,
  textarea {
    border-width: 1.5px !important;
    border-color: map-get($theme-colors, 'light-grey') !important;
    border-radius: 5px !important;

    &:active,
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
      border-color: map-get($theme-colors, 'primary') !important;
    }
  }
}

div.react-datepicker__header {
  color: map-get($theme-colors, 'dark-black') !important;
}

div.form-group {
  select {
    border: 1.5px solid map-get($theme-colors, 'primary') !important;
    border-radius: 0 5px 5px 0 !important;
  }
}

div.form-group {
  select#perPage {
    border: 1.5px solid map-get($theme-colors, 'neutral') !important;
    border-radius: 5px !important;
  }
}

// div.form-group {
//   select#regionSelect, select#districtSelect {
//     border: 1.5px solid map-get($theme-colors, 'neutral') !important;
//     border-radius: 5px !important;
//   }
// }

.form-group > select:nth-child(2) {
  border: 1.5px solid map-get($theme-colors, 'neutral') !important;
  border-radius: 5px !important;
}

// select#selectYear,
// select#profileId {
//   border: 1.5px solid map-get($theme-colors, 'primary') !important;
//   border-radius: 0 5px 5px 0 !important;
// }

div.custom-file {
  label {
    border: 1.5px solid map-get($theme-colors, 'primary') !important;
    border-radius: 5px !important;
    color: map-get($theme-colors, 'primary') !important;
    background-color: map-get($theme-colors, 'white') !important;

    &:active,
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
      border-color: rgba(map-get($theme-colors, 'primary'), 0.6) !important;
    }

    &::after {
      margin-top: 0 !important;
      font-size: initial !important;
      background-color: map-get($theme-colors, 'primary') !important;
      transition: 0.2s !important;
      border-radius: 0 !important;
      color: map-get($theme-colors, 'white') !important;

      &:hover,
      &:active,
      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
        opacity: 0.6 !important;
      }
    }
  }
}

// .sc-hCcOGj.kaIVMF {
//   i {
//     color: map-get($theme-colors, 'primary') !important;

//     &:hover {
//       color: map-get($theme-colors, 'dark-primary') !important;
//     }
//   }
// }

div.custom-radio {
  label {
    &::before,
    &::after {
      border-radius: 50% !important;
    }
  }
}

.search-bar {
  input {
    border: 1.5px solid map-get($theme-colors, 'primary') !important;
    font-weight: 0 !important;
    border-radius: 5px 0 0 5px !important;
    color: map-get($theme-colors, 'primary') !important;
    background-color: map-get($theme-colors, 'white') !important;

    &::placeholder {
      font-weight: normal !important;
    }

    &:active,
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
      border-color: rgba(map-get($theme-colors, 'primary'), 0.6) !important;

      &::placeholder {
        color: map-get($theme-colors, 'dark-primary') !important;
        font-weight: 500 !important;
      }
    }
  }

  button {
    margin-top: 0 !important;
    font-size: initial !important;
    background-color: map-get($theme-colors, 'primary') !important;
    transition: 0.2s !important;
    border-radius: 0 5px 5px 0 !important;
    color: map-get($theme-colors, 'light-primary') !important;

    &:hover {
      outline: 0 !important;
      box-shadow: none !important;
      background-color: map-get($theme-colors, 'dark-primary') !important;
    }

    &:active,
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
      background-color: map-get($theme-colors, 'primary') !important;
    }
  }
}

div.table-responsive {
  thead {
    th {
      background-color: map-get($theme-colors, 'table-thead') !important;
    }
  }

  tr.summary-tr {
    background-color: map-get($theme-colors, 'table-thead') !important;
    &:hover {
      background-color: #e5e5e5 !important;
    }
  }
}

button.btn-primary,
a.btn-primary {
  &:hover {
    background-color: map-get($theme-colors, 'dark-primary') !important;
  }
}
