@import './../../Variables.scss';

.error-page {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  h1 {
    font-size: 80px;
    font-weight: 700;
    font-family: $RobotoSlab;
  }
  p {
    font-size: 18px;
  }
  @media only screen and(max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .u-center-vertical {
      position: relative;
      top: inherit;
      transform: inherit;
    }
    img {
      margin-top: 40px;
      width: 350px;
    }
  }
}
