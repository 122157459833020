@import '../../Variables.scss';

.button-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.new-condo-modal {
  max-width: inherit !important;
  width: 75% !important;
  .modal-content {
    .modal-body {
      p {
        text-align: left !important;
        font-size: 14px;
      }
      .separator {
        border-right: 2px solid map-get($theme-colors, 'primary');
        @media only screen and (max-width: 767px) {
          border-right: 0;
        }
      }
    }
  }
}
