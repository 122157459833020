@import './../../Variables.scss';

.summary-table {
  padding: 0 16px;
  .row {
    p {
      margin-top: 8px;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 13px;
    }
  }
}

// Class for the heading row
.heading-row {
  background-color: transparent;

  .col-4,
  .col-3,
  .col-2 {
    p {
      text-transform: uppercase;
      color: map-get($theme-colors, 'primary');
      text-align: right;
    }
  }
}

// Class for the first type of row
.normal-row {
  .col-4,
  .col-3,
  .col-2 {
    background-color: map-get($theme-colors, 'white');
    border-bottom: 2px solid map-get($theme-colors, 'grey');

    &:first-child {
      border-right: 2px solid map-get($theme-colors, 'grey');
      p {
        color: map-get($theme-colors, 'dark-grey');
      }
    }
    &:nth-child(2),
    &:nth-child(3) {
      border-right: 2px solid map-get($theme-colors, 'grey');
      text-align: right;
    }
    &:last-child {
      text-align: right;
    }
  }
}

// Class for the second type of row
.highlighted-row {
  .col-4,
  .col-3,
  .col-2 {
    border-bottom: 2px solid map-get($theme-colors, 'white');
    background-color: map-get($theme-colors, 'grey');

    &:first-child {
      border-right: 2px solid map-get($theme-colors, 'white');
      p {
        color: map-get($theme-colors, 'dark-black');
      }
    }
    &:nth-child(2),
    &:nth-child(3) {
      border-right: 2px solid map-get($theme-colors, 'white');
      text-align: right;
    }
    &:last-child {
      text-align: right;
    }
  }
}

.summary-tr {
  background-color: map-get($theme-colors, 'light-primary');
  td {
    font-weight: 700;
    color: map-get($theme-colors, 'black') !important;
  }
}

.affiliation {
  background-color: transparent;
  border: 2px solid;
  margin-left: 5px;
  font-weight: 500;
  border-radius: 0;
  text-transform: uppercase !important;
  @media only screen and (max-width: 767px) {
    margin-top: 14px;
  }
  &.active {
    color: map-get($theme-colors, 'success');
    border-color: map-get($theme-colors, 'success');
  }
  &.not-active {
    color: map-get($theme-colors, 'red');
    border-color: map-get($theme-colors, 'red');
  }
}

.flex-between {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .btn-add-summary {
    padding: 1px 10px;
  }
}
