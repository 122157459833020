@import './../../App.scss';

.header-crm {
  position: fixed;
  // width: calc(100% - 200px);
  width: 100%;
  right: 0;
  top: 0;
  padding: 16px 32px 16px 70px;
  background-color: map-get($theme-colors, 'primary');
  z-index: 10;
  h2 {
    color: map-get($theme-colors, 'light-primary');
    font-family: $RobotoSlab;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
    svg {
      width: 30px;
      max-width: 100%;
      height: 100%;
      margin-right: 18px;
      fill: map-get($theme-colors, 'light-primary');
    }
  }
  .cont-header-element {
    width: max-content;
    float: right;
    padding-top: 3px;
    p {
      margin-bottom: 0;
      float: right;
      margin-right: 21px;
      span {
        font-weight: 700;
      }
    }
    .btn-logout {
      background-color: map-get($theme-colors, 'light-primary');
      float: right;
      margin: 0;
      padding: 5px !important;
      margin-top: -7px;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .btn-notifications {
      background-color: map-get($theme-colors, 'light-primary');
      float: right;
      margin: 0;
      padding: 5px !important;
      margin-top: -7px;
      margin-right: 10px !important;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .btn-lang {
      float: right;
    }
    .credits {
      color: map-get($theme-colors, 'light-primary');
      font-family: $RobotoSlab;
      font-size: 23px;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: uppercase;
      position: relative;
      left: 0;
      top: -5px;
    }
  }
  @media only screen and (max-width: 991px) {
    // width: 100%;
    padding: 16px 32px;
    h2 {
      text-align: center;
      margin-bottom: 8px;
    }
    .cont-header-element {
      float: inherit;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .dropdown-menu-custom {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
    max-width: 380px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: map-get($theme-colors, 'primary');
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: map-get($theme-colors, 'primary');
    }
    scrollbar-width: thin;
    scrollbar-color: #f1f1f1;
  }
}

.btn-notification.btn-primary:hover {
  background-color: #fff9e2 !important;
}
