@import '../../Variables.scss';

.position-btn-filter {
  .spinner-container {
    margin-top: 10px;
  }
}

.new-invoice-modal {
  max-width: inherit !important;
  width: 90% !important;
  .modal-content {
    .modal-body {
      p {
        text-align: left !important;
        font-size: 14px;
      }
      .separator {
        border-right: 2px solid map-get($theme-colors, 'primary');
        @media only screen and (max-width: 767px) {
          border-right: 0;
        }
      }
    }
  }
}

.open-link {
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: 700;
}

.align-center {
  align-items: center;
}

.show-invoices {
  .search-bar {
    input {
      background-color: #f3f3f3;
    }
    .input-group-append {
      .btn {
        margin-left: 0 !important;
      }
    }
  }
  .eye-toggle {
    margin-left: 20px !important;
    width: 40px;
  }
  .show-me {
    color: #666666;
  }
}
