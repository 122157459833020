@import './../../Variables.scss';

.profile-form {
  .form-control:disabled {
    background-color: map-get($theme-colors, 'white');
  }
  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.email-support {
  text-align: center;
  border-top: 0;
  p {
    margin-bottom: 0;
    font-family: $RobotoSlab;
    font-weight: 700;
    color: map-get($theme-colors, 'primary');
    svg {
      margin-right: 5px;
    }
    a {
      margin-left: 5px;
      color: map-get($theme-colors, 'black');
    }
  }
}
