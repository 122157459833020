@import './../../Variables.scss';

.sct-start {
  .header-logo {
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: map-get($theme-colors, 'white');
    border-bottom: 3px solid map-get($theme-colors, 'primary');
    -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;
    img {
      max-height: 60px;
    }
  }
  .position-lang {
    text-align: center;
    margin-top: 20px;
  }
}
