@import './../../App.scss';

footer {
  position: fixed;
  // width: calc(100% - 200px);
  width: 100%;
  right: 0;
  bottom: 0;
  background-color: map-get($theme-colors, 'grey');
  padding: 8px 32px;
  font-size: 12px;
  z-index: 9;
  // @media only screen and (max-width: 991px) {
  //   width: 100%;
  // }
}
