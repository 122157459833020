@import './../../Variables.scss';

.registry-name {
  margin-bottom: 0 !important;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 15px;
  }
}

.nav-tabs {
  border-bottom: 0;
  .nav-item {
    .nav-link {
      font-size: 0.8rem;
      border-radius: 0;
      border: 0;
      border-bottom: 3px solid map-get($theme-colors, 'background');
      padding: 8px 16px;
      text-transform: uppercase;
      font-weight: 700;
      color: map-get($theme-colors, 'dark-grey');
      transition: 0.2s;
      &.active {
        border-bottom: 3px solid map-get($theme-colors, 'primary');
        color: map-get($theme-colors, 'primary');
      }
      &:hover {
        cursor: pointer;
        color: map-get($theme-colors, 'primary');
        text-decoration: none;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.tab-content {
  background-color: map-get($theme-colors, 'white');
  border-top: 0;
  padding: 30px 16px;
  .form-group {
    .custom-radio {
      margin-top: 7px;
      .custom-control-label {
        font-size: 15px;
        font-style: normal;
        color: map-get($theme-colors, 'black');
        font-weight: 500;
      }
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: map-get($theme-colors, 'primary');
        color: map-get($theme-colors, 'primary');
        border-color: map-get($theme-colors, 'primary');
      }
    }
    .input-group {
      .input-group-prepend {
        .input-group-text {
          color: map-get($theme-colors, 'black');
          border: 0;
          background-color: map-get($theme-colors, 'grey');
          font-weight: 700;
        }
      }
    }
  }
}

.master-row {
  .master-col {
    display: flex;
    align-items: center;
    h4 {
      margin-bottom: 0;
    }
    .form-group {
      width: calc(100% - 25px);
      margin-left: 25px;
    }
  }
  @media only screen and (max-width: 767px) {
    .master-col {
      display: inline;
      align-items: inherit;
      h4 {
        margin-bottom: initial;
        text-align: center;
      }
      .form-group {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
