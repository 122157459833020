@import './../../Variables.scss';

.blocking-modal-spinner {
  text-align: center;
  .spinner-border {
    border-color: map-get($theme-colors, 'primary-contrast');
    border-right-color: transparent;
    border-width: 0.5em;
    width: 100px;
    height: 100px;
  }
}

.blocking-content-modal {
  background-color: transparent !important;
  box-shadow: none !important;
}
