@import './../../Variables.scss';

#filePSI {
  height: 200px !important;
}

.custom-file {
  height: auto;
}

.custom-file-label[for='filePSI'] {
  border: 1.5px grey dashed !important;
  height: 200px !important;
  text-align: center !important;
  line-height: 185px;
}

.custom-file-label[for='filePSI']::after {
  content: '' !important;
  padding: 0 !important;
}

#fileDocument {
  height: 200px !important;
}

.custom-file {
  height: auto;
}

.custom-file-label[for='fileDocument'] {
  border: 1.5px grey dashed !important;
  height: 200px !important;
  text-align: center !important;
  line-height: 185px;
}

.custom-file-label[for='fileDocument']::after {
  content: '' !important;
  padding: 0 !important;
}

.linkButton {
  color: map-get($theme-colors, 'primary') !important;
}
