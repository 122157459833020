@import '../../Variables.scss';

.year-select {
  border-radius: 0 !important;
  border: 2px solid map-get($theme-colors, 'grey');
  border-bottom-color: map-get($theme-colors, 'primary');
}

.button-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
